.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0;
  display: block;
}
.ant-collapse
  > .ant-collapse-item.ant-collapse-no-arrow
  > .ant-collapse-header {
  padding: 0;
}
.password {
  .ant-input::placeholder {
    color: #000;
  }
}
.no-label {
  margin-top: 30px;
}
.blockTextLight {
  background: #fff;
  padding: 5px 0;
  border-radius: 5px;
  color: black;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  display: block;
}
