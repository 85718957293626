.formGroup {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.titleInput {
  border-color: #d9d9d9;
}

.errorInput {
  border-color: #ff4d4f;
}

#feedback {
  color: #ff4d4f;
  padding-left: 10px;
}
