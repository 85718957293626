.snapshotChart {
  background: white;
  padding: 20px 30px;
  margin-bottom: 30px;
  border-radius: 8px 0 8px 8px;
  .chart {
    height: 350px;
  }
}

.chartControls {
  padding-top: 20px;
}

.box .reportTable {
  margin: 0 -30px;
}
