.workflows {
  table {
    background-color: white;
  }
}

.workflow {
  &.inList {
    border-top: $light_grey 1px solid;
    padding-top: 1rem;
    margin-top: 1rem;
    &:first-child {
      padding-top: 0;
      margin-top: 0;
      border: 0;
    }
  }
}

.newWorkflowAction {
  padding: 20px 30px;
  margin: 0 -30px -20px;
  background-color: $background-color;
  border-top: 1px solid $light-grey;
}

.newWorkflow {
  padding: 0 30px;
  margin: 0 -30px -20px;
}

.newWorkflowCriteria {
  padding: 20px 30px;
  margin: 0 -30px -20px;
  background-color: $background-color;
  border-top: 1px solid $light-grey;
  border-bottom: 1px solid $light-grey;
}

.criteria {
  padding: 20px 30px;
  margin: 0 -30px -20px;
  background-color: $off_white;
  //border-top: 1px solid $grey;
}
.then_divider {
  padding-top: 16px;
}

//styling for workflow active toggle
.toggleSwitch.ant-switch {
  background-color: red;
}
.toggleSwitch.ant-switch-checked {
  background-color: green;
}
