@import "utilities/_colours.scss";
@import "utilities/controls.scss";
@import "utilities/pagination.scss";
@import "utilities/tables.scss";
@import "utilities/typography.scss";
@import "utilities/boxes.scss";
@import "utilities/menus.scss";

@import "../components/dashboard/dashboard.scss";
@import "../components/dashboard/widget/widget.scss";

@import "../components/apps/apps.scss";

@import "../components/header/header.scss";
@import "../components/footer/footer.scss";
@import "../components/explorer/explorer.scss";

@import "../components/generic/title/title.scss";
@import "../components/generic/tables/table.scss";

@import "../components/users/users.scss";
@import "../components/workflows/workflows.scss";
@import "../components/workflow/logs/logs.scss";
@import "../components/reports/reports.scss";

@import "../components/report/report.scss";
@import "../components/report/controls/controls.scss";
@import "../components/report/filters/filters.scss";
@import "../components/report/data/data.scss";
@import "../components/report/columns/columns.scss";
@import "../components/report/snapshots/snapshots.scss";
@import "../components/apps/dataPush/integration/transactions/transactions.scss";
@import "../components/generic/components/statusIcon";
@import "../components/settings/settings.scss";
@import "../components/role/permissions/permissions.scss";
@import "components/clickableCell.scss";
body {
  background: $background-color;
}

.itemPath {
  padding: 25px;
}
.hidden {
  display: none;
}

.middle {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
