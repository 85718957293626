.snapshots,
.export,
.reportColumns {
  background: white;
  padding: 20px 30px;
  .react-grid-item > .react-resizable-handle {
    display: none;
  }
}
.reportData {
  .controls {
    background: white;
    padding: 20px 30px;
  }
}
// React Grid Layout Styling:
.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  background: $blue;
  border-radius: 5px;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
