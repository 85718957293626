.vlm {
  background: white;
  border-left: 20px solid $light-blue;
  border-right: 20px solid $light-blue;
  padding: 0 30px 100px;

  h1 {
    color: $blue;
    padding: 30px 0;
  }
  .carrier {
    border-bottom: 3px solid $light-grey;
    padding: 25px 0 0;
  }

  .shelf {
    position: relative;
    height: 200px;
  }

  .location {
    position: absolute;
    border: 1px solid $grey;
  }
}
