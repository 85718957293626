// Static Rack
.type_1 {
  border-left: 20px solid $grey;
  border-right: 20px solid $grey;
  padding-bottom: 20px;

  &::after {
    content: " ";
    height: 80px;
    width: "100%";
    display: block;
    margin: -50px 0 40px;

    border-top: 20px solid $grey;
  }
  h4 {
    padding: 100px 30px 50px;
  }
}

// VLM
.type_5 {
  border-left: 20px solid $light-blue;
  border-right: 20px solid $light-blue;
  padding-bottom: 20px;
  background: white;
  &::after {
    content: " ";
    background: $very-light-grey;
    height: 60px;
    width: "100%";
    display: block;
    margin: -30px 0 40px;
  }
  h4 {
    padding: 100px 30px 50px;
  }
}
