.workflow {
  .title {
    flex-grow: 1;
    margin: 0;
    .separator,
    a,
    h1 {
      font-size: 1.2rem;
      display: inline-block;
      font-weight: 500;
      margin: 5px;
    }
  }
}
.separator {
  padding: 20px 10px;
}
