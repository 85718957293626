.transaction-row {
  background: white;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid $light-grey;
  margin-bottom: 5px;
}
.transaction-row-header {
  padding: 10px;
  font-weight: 650;
}
