.titleInput {
  border-color: #d9d9d9;
}

.errorInput {
  border-color: #ff4d4f;
}

#feedback {
  color: #ff4d4f;
  font-size: 14px;
  line-height: 1.5715;
  font-weight: lighter;
  height: 30px;
}
