@import "./storageUnits/storageUnit/storageUnit.scss";
@import "./storageUnits/storageUnits.scss";
@import "./carriers/carriers.scss";

@import "./materials/material/material.scss";

.bigItems {
  display: flex;
}

.bigItem {
  margin-right: 50px;
  h4 {
    color: $grey;
    margin-bottom: 0;
  }
  span {
    font-size: 2rem;
    font-weight: bold;
    color: $dark-grey;
  }
  display: block;
}

.textButton {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: $blue;
}
