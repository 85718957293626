@import "./customDisplays/currentStorageUnitLoad/currentStorageUnit";
@import "./customDisplays/divertTool/divertTool";

#displays {
  .genericDisplay {
    background: $background-color;

    &.fullscreen-enabled .controls {
      margin-left: -10px;
      margin-right: -10px;
    }
  }
}
