p,
span,
a,
h1,
h2,
h3,
h4,
h5,
input,
button,
label {
  font-family: "Montserrat", sans-serif;
}

h1 {
  font-size: 2rem;
}

a {
  &:hover {
    text-decoration: none;
  }
}

span.mini {
  font-size: 0.8rem;
  color: $light-grey;
  text-transform: uppercase;
  display: block;
}

.blockText {
  background: $light-grey;
  padding: 5px 0;
  border-radius: 5px;
  color: white;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;

  display: block;
}
