.pagination {
  margin: 0.8rem 0;
  text-align: center;

  ul {
    margin: 0 auto;
  }
  .showAmount {
    margin-left: 20px;
  }
  .pageAmount {
    padding: 5px;
  }
}
