.currentOrder {
  > span {
    margin-top: -5px;
  }

  .newOrderLine {
    padding: 20px 30px 0;
    margin: 0 -30px -20px;
    border-radius: 0 0 5px 5px;
    background-color: #ebeef2;
    border: 1px solid #bdbed4;
  }

  .line {
    border-top: 1px solid $light-grey;
    display: flex;
    align-items: center;
    padding: 10px 30px;
    margin: 0 -30px;

    &.outOfStock {
      border: 0;
      background-color: $very-light-red;
    }

    .title {
      flex-grow: 1;
    }

    .stock {
      float: left;
      color: $grey;
    }

    h4 {
      margin: 0;
    }

    .quantity {
      margin: 0 10px;
    }

    &:first-child {
      border: 0;
    }
  }
}
