.userGroupMenu h2 {
  margin: 0 -10px 20px;
  padding-bottom: 10px;
  border-bottom: $light_grey 1px solid;
}

.permissionArea {
  border-bottom: 1px solid $light-grey;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.user {
  &.inList {
    border-top: $light_grey 1px solid;
    padding-top: 1rem;
    margin-top: 0.5rem;
    &:first-child {
      padding-top: 0;
      margin-top: 0;
      border: 0;
    }
    h3 {
      margin: 0;
    }
  }
}

.login {
  .errors {
    margin-bottom: 20px;
  }
  button {
    margin-top: 10px;
  }
}

.msButton {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;
  background: #2f2f2f;
  color: #fff;
  display: inline;
  padding: 5px 15px;
  object {
    vertical-align: middle;
    height: 1em;
    width: 1em;
    position: relative;
  }
  p {
    vertical-align: middle;
    display: inline;
  }
}
