.snapshot {
  border-bottom: 1px solid $very-light-grey;
  margin: 0 -30px;
  padding: 20px 30px 0;
}

.snapshot .name,
.newSnapshot .name {
  border: 0;
  border-bottom: 1px solid $very-light-grey;
  font-size: 1.4rem;
  border-radius: 0;
  padding-left: 5px;
  width: 100%;
  background: none;
}
.name.new {
  border-bottom: 1px solid $light-grey;
}

.newSnapshot {
  padding: 20px 30px 0;
  margin: 0 -30px -20px;
  border-radius: 0 0 5px 5px;
  background-color: $background-color;
  border: 1px solid $light-grey;
}

.triggerInterval {
  width: 100%;
}
