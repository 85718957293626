.dashboard {
  .chart {
    height: 300px;
  }
  .box,
  .widgetBox {
    h3,
    h5 {
      padding: 20px 30px;
    }
    .ant-page-header {
      padding: 10px 0 30px;
    }
    h3 {
      font-weight: bold;
    }
  }

  .box {
    padding: 10px 24px;
  }

  .widgetBox {
    min-height: 250px;
    background: white;
    padding: 20px 30px;
    border-radius: 5px;
    border: 1px solid $light-grey;
    margin-bottom: 20px;
  }

  .chartType {
    margin-left: 20px;
    display: inline-block;
  }
  .icon {
    height: 16px;
    margin-top: -5px;
  }
  // React Grid Layout Styling:
  .react-grid-layout {
    position: relative;
    transition: height 200ms ease;
  }
  .react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top;
  }
  .react-grid-item.cssTransforms {
    transition-property: transform;
  }
  .react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
  }

  .react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
  }

  .react-grid-item.react-grid-placeholder {
    background: $blue;
    border-radius: 5px;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  .react-resizable-hide > .react-resizable-handle {
    display: none;
  }
  .react-grid-item > .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    background-image: url(../../images/resize.svg);
    background-position: bottom right;
    background-size: contain;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: se-resize;
  }
}
