.permission-row {
  .ant-col {
    text-align: center;
  }
}
.middle-switch {
  .ant-switch,
  .ant-switch-checked,
  .ant-switch-handle {
    left: calc(30%);
  }
  .group[data-selected="true"] {
    margin-left: 0.66rem;
  }
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0;
  display: flex;
}
.ant-collapse
  > .ant-collapse-item.ant-collapse-no-arrow
  > .ant-collapse-header {
  padding: 0;
}
.permission-box {
  background: white;
  border-radius: 5px;
  margin-bottom: 20px;
}
.ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding: 0px;
}
.permissions-grid {
  border: 1px solid #bdbed4;
  display: grid;
  grid-template-columns: 3fr repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
  .align-left {
    text-align: left;
    padding-left: 20px;
    > h2 {
      margin: 0px;
    }
  }
  > div {
    background: $white;
    text-align: center;
    padding: 10px;
  }
}
