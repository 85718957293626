.centered-container {
  min-height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container {
  display: inline-flex;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 99px;
  border: 2px solid #d3d9e0;
  background: #ebeef2;
}

.restart-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
}

.restart-message {
  color: var(--primary-almost-black, #363636);
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  padding-top: 1rem;
}

.restart-instructions {
  color: var(--neutral-13, #000);
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
}

.email {
  color: #3b8bea;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
}

.image {
  width: 25px;
  height: 25px;
}
