footer {
  .footerMenu {
    border-top: 1px solid $light-grey;
    padding-top: 10px;
    margin: 20px 0;
    display: flex;
  }
  .left {
    flex-grow: 1;
  }
  .right {
    a {
      margin-left: 15px;
    }
  }
}
