.controlButtons {
  text-align: right;
  margin-top: 10px;
  button {
    margin-left: 10px;
  }
}

.controls {
  .ant-btn-group {
    margin-right: 8px;
  }
}

.subMenu {
  background: none;
  padding: 10px 0 16px;
  margin: 0;
}
