.box {
  background: white;
  padding: 20px 30px;
  border-radius: 5px;
  border: 1px solid $light-grey;
  margin-bottom: 20px;

  &.inList {
    padding: 15px 20px;
    h3 {
      margin-bottom: 0;
    }
  }
}
