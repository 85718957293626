.report {
  .controls {
    //display: flex;

    .title {
      flex-grow: 1;
      h1 {
        margin-bottom: 4px;
      }
    }
  }
  .controlTabs {
    text-align: right;
    margin-bottom: 10px;
    a {
      color: $grey;
      background-image: url(./tabBg.svg),
        linear-gradient(-90deg, #d7dce3 0%, #e2e6eb 100%);
      background-repeat: no-repeat repeat;
      padding: 12px 20px 12px 40px;
      border-radius: 0 10px 0 0;
      &.active {
        background-image: url(./tabBg.svg),
          linear-gradient(-90deg, white 0%, white 100%);
      }
      &:hover {
        background-image: url(./tabBg.svg),
          linear-gradient(-90deg, $light-grey 0%, $light-grey 100%);
      }
    }
  }
}
