.material {
  #location_contents {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    li {
      border-bottom: 1px solid $very-light-grey;
      padding: 10px 0;
      span {
        float: right;
      }
    }
  }
}
