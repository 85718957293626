.controls {
  .title {
    margin: 10px 0 0;
  }

  .noEdit {
    display: flex;
  }
  .titleActions {
    display: flex;
    margin: 8px 15px;
  }
  .editButton,
  .deleteButton,
  .favouriteButton {
    background: none;
    cursor: pointer;
    padding: 8px;
    border: 0;
    &:hover {
      opacity: 1;
    }
  }
}

.titleInput {
  .ant-input {
    font-size: 2rem;
  }
  font-family: "Montserrat", sans-serif;
  margin: 0;
  border: 0;
  border-bottom: 1px solid $grey;
  font-size: 2rem;
  padding: 0;
  font-weight: 500;
  min-width: 80%;
  border-radius: 0;
  line-height: 1.2;
  outline: none;
}

.saveButton {
  cursor: pointer;
  padding: 8px 15px;
  border: 0;
  outline: none;
  background: none;
}
