$very-light-grey: #ebeef2;
$light-grey: #bdbed4;
$grey: #82878e;
$dark-grey: #444b53;
$black: #000000;
$very-light-blue: #e9f4ff;
$light_blue: #73b9ff;
$blue: #0080ff;

$very-light-red: #ffdcdc;
$light-red: #ffb8b8;
$red: #d0021b;
$orange: #ffa000;
$purple: #8401f0;
$green: #00c851;
$background-color: #ebeef2;
$off_white: #e9fafa;
$white: #ffffff;
