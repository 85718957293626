.order {
  border: 1px solid $light-grey;
  align-items: center;
  padding: 10px 24px;
  background: $white;
}
.noTopBorder {
  border-right: 1px solid $light-grey;
  border-bottom: 1px solid $light-grey;
  border-left: 1px solid $light-grey;
}

.skipped {
  @extend .noTopBorder;
  padding: 10px 24px;
  color: $grey;
}

.wrapText {
  word-wrap: break-word;
}
.upperCase {
  text-transform: uppercase;
}
.processing {
  @extend .noTopBorder;
  padding: 10px 24px;
  background: $blue;
  color: $white;
}

.material {
  padding: 0;
  align-items: center;
  background: $white;
}
.materialName {
  padding: 10px 24px;
  border-top: 0;
  @extend .noTopBorder;
}
.materialText {
  font-weight: 500;
  border: 0;
  text-align: left;
  box-shadow: none;
  margin: 0;
}
.next {
  margin: 10px;
}

.serial {
  padding: 10px 24px;
  @extend .noTopBorder;
  border-top: 0;
  .ant-collapse-item {
    border: 0;
    .ant-collapse-header {
      padding: 0;
    }
  }
}

.serialNumbers {
  @extend .serial;
  background: $background-color;
  .ant-collapse-header {
    border-bottom: 0 solid $background-color;
  }
}

.serialNumbersSelected {
  @extend .serial;
  background: $grey;
  .ant-collapse-item {
    background: $grey;
    .ant-collapse-header {
      color: $background-color;
      border-bottom: 1px solid $background-color;
      padding-bottom: 10px;
      .ant-icon {
        color: $background-color;
      }
    }
  }
  .ant-collapse-content-box {
    background: $grey;
    color: $background-color;
  }
}

.batchControlButtons {
  margin: 0;
  text-align: right;
}
.panel > * {
  //padding: 0;
  border: 0;
}
.materialHeader {
  @extend .noTopBorder;
  padding: 5px 24px;
  background: $white;
}
