.mapping {
  margin-bottom: 5px;
}

.arrowRight,
.arrowLeft {
  margin-top: 5px;
  min-height: 32px;
  min-width: 32px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 32px;
  background-image: url(./images/rightArrow.svg);
}
.arrowLeft {
  background-image: url(./images/leftArrow.svg);
}

.queuedTasks {
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    list-style: none;
    border-bottom: 1px solid $very-light-grey;
    padding: 15px 0;
    display: flex;
    flex-direction: row;
    .data {
      flex-grow: 1;
    }
  }
}

.newMapping {
  padding: 20px 30px 0;
  margin: 10px -30px -20px;
  border-radius: 0 0 5px 5px;
  background-color: $background-color;
  border: 1px solid $light-grey;
}

.newIntegration {
  .name {
    flex-grow: 1;
  }
}
