.divertTool {
  background: white;
  margin: -25px -25px 0;
  height: 100vh;

  .handlingUnitForm,
  .orderName,
  .storageUnits {
    margin: 25px 40px;
    label,
    h2 {
      font-weight: bold;
      font-size: 24px;
    }
    span {
      font-size: 32px;
    }
  }

  .storageUnitInfo {
    height: 100%;
    background-color: $background-color;
    h1 {
      text-align: center;
      font-weight: bold;
      font-size: 480px;
    }

    &.colorP {
      background-color: yellow;
    }
    &.color1 {
      background-color: green;
    }
    &.color2 {
      background-color: blue;
    }
    &.color3 {
      background-color: grey;
    }
  }
}
