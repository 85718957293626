.homeIconContainer {
  border: 1px solid #eaecf0;
  border-radius: 8px;
  padding: 10px;
  display: inline-block; /* Make sure the container only takes the space of its content */
}

.homeIcon {
  width: 24px;
  height: 24px;
}

.warningIcon {
  color: #a65900;
  font-size: 20px;
  height: 24px;
  width: 24px;
}

.header {
  font-size: 18px;
  font-weight: 500;
  padding-left: 6px;
}

.subHeader {
  color: #475467;
  font-size: 16px;
  padding-top: 18px;
  font-weight: 500;
}

.paragraph {
  color: #475467;
  font-size: 12px;
  padding-top: 10px;
}

.licenseInfo {
  display: flex;
  justify-content: space-between;
}
.licenseLimit {
  color: #101828;
  font-size: 32px;
  font-weight: 700;
}

.smLicenseLimit {
  color: #101828;
  font-size: 18px;
  font-weight: 700;
}

.licenseReached {
  border-radius: 99px;
  background: #fff4b8;
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
  font-weight: 400;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: -10px;
}

.updateLicenseButton {
  &.ant-btn-lg {
    border-radius: 8px;
  }
}
