.success {
  color: green;
  font-size: 20px;
  padding-left: 15px;
}

.fail {
  color: red;
  font-size: 20px;
  padding-left: 15px;
}

.loading {
  font-size: 20px;
  padding-left: 15px;
}
