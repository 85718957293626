@import "../../stylesheets/utilities/_colours.scss";

.reports {
  table {
    background-color: white;
  }
}

.report {
  &.inList {
    border-top: $light_grey 1px solid;
    padding-top: 0.7rem;
    &:first-child {
      padding-top: 0;
      margin-top: 0;
      border: 0;
    }
  }
}

.reportLoading {
  padding: 60px 0 0;
  .loadingText {
    text-align: center;
    display: block;
    padding: 20px;
    font-size: 24px;
  }
}

.reportEdit {
  .permission,
  .reportColumn {
    padding: 15px 0;
    border-top: 1px solid $light-grey;
  }
}

.newReportColumn,
.newFilter {
  padding: 20px 30px 0;
  margin: 0 -30px -20px;
  border-radius: 0 0 5px 5px;
  background-color: $background-color;
  border: 1px solid $light-grey;
}

.newReport {
  padding: 0 30px;
  margin: 0 -30px -20px;
}

.buttonless {
  background: none;
  border: 0px;
}

.fullWidth {
  width: 100%;
}
.disabledButton {
  color: white;
  cursor: default;
  background: #40a9ff;
  width: 100%;
  :hover {
    color: white;
    cursor: default;
    background: #40a9ff;
    width: 100%;
  }
}
.reportListFilters {
  margin-top: 20px;
}
